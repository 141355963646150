import React from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";

import ReactGA from "react-ga";

const analyticsLinkClick = (link) => {
    ReactGA.event({
      category: 'Category',
      action: `Clicked category link: ${link}`
    })
}

const Sidebar = ({ categories }) => {
    return <List>
        { categories.map((category, index) => {
            const { name } = category;
            const slug = name.split(" ").join("_");

            const { src } = category.childrenFile[0].childImageSharp.fixed;
            return <Link  key={ index } to={ slug } onClick={ () => analyticsLinkClick(slug) }>
                <li><Logo src={ src } /><Name>{ name }</Name></li>
            </Link>;
        }) }
    </List>
}

const List = styled.ul`
    list-style: none;
    margin: 0;
    font-size: 14px;
    background: #ebebeb;
    border-radius: 5px;
    padding: 24px 12px 24px 0;

    li {
        display: flex;
        align-items: center;
        color: #2c2c2c;
        font-weight: bold;
    }

    a {
        display: block;
        border-radius: 20px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        padding: 12px 0 12px 24px;
        text-transform: capitalize;

        &:hover {
            background: #d3caca;
        }
    }

    @media (max-width: 480px) {
        display: flex;
        overflow-x: scroll;
        margin-bottom: 24px;
        padding: 0 0 6px 0;
        overflow-y: hidden;

        ::-webkit-scrollbar {
            height: 6px;
        }
        
        ::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px #a6a6a638;
        }
        
        ::-webkit-scrollbar-thumb {
            background-color: darkgrey;
            outline: 1px solid #f2f2f2;
            border-radius: 30px;
        }

        a {
            border-radius: 20px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top-right-radius: 0;
            padding: 16px 12px;

            &:hover {
                background: #d3caca;
            }
        }
    }
`;

const Name = styled.span`
    @media (max-width: 990px) {
       visibility: hidden;
       width: 0;
       height: 0;
    }
`;

const Logo = styled.span`
    width: 15px;
    height: 15px;
    margin-right: 8px;
    ${ props => css`
        background-image: url(${ props.src });
        background-repeat: no-repeat;
        background-size: cover;
    `}
    display: block;
`;

export default Sidebar;