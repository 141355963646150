import React from "react";
import styled, { css } from "styled-components";
import { graphql } from "gatsby";
import ResourceBox from "../components/ResourceBox";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Header";

import normalise from "../utils/normalise";
import Sidebar from "../components/Sidebar";

export default ({ data }) => {
    const { childrenFile, name } = data.allCategory.nodes[0];
    const { src } = childrenFile[0].childImageSharp.fixed;
    const { categories } = data;

    return <Layout>
    <SEO title="Home" />
    <Header />
    <Container>
        <Sidebar categories={ categories.nodes } />
        <div>
            <Title><Logo src={ src } /> { name } </Title>
            <ResourceWrapper>
            { data.allCategory.nodes[0].childrenResource.map((resource, index) => {
                const { name, link, description, categories, childrenFile } = resource;
                const { src } = childrenFile[0].childImageSharp.fixed;

                return <ResourceBox 
                    key={ index }
                    description={ description }
                    name={ name } 
                    link={ link }
                    image={ src }
                    categories={ normalise(categories)  } /> }) }
            </ResourceWrapper>
        </div>
    </Container>
    </Layout>
}

const Container = styled.div`
    max-width: 1320px;
    margin: 0 auto;
    padding: 80px 20px;
    display: grid;
    grid-template-columns: 250px auto;
    grid-column-gap: 40px;

    @media (max-width: 480px) {
        display: block;
    }

    @media  (max-width: 990px) {
        grid-template-columns: 60px auto !important;
    }
`;

const ResourceWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 25px;
    grid-column-gap: 12px;

    @media (max-width: 1310px) {
        grid-template-columns: repeat(2, 1fr) !important;
    }

    @media  (max-width: 800px) {
        grid-template-columns: 1fr !important;
    }
`;

const Title = styled.h1`
    display: flex;
    align-items: center;
    margin: 0;
    margin-bottom: 36px;
    text-transform: capitalize;
`;

const Logo = styled.span`
    ${ props => css`
    background: url(${props.src}); 
    background-size: contain;
    background-repeat: no-repeat;` }
    width: 35px;
    height: 35px;
    display: inline-block;
    margin-right: 12px;
`;

export const query = graphql`
    query($name: String!) {
        allCategory(filter: {name: {eq: $name}}) {
          nodes {
            childrenResource {
              logo
              name
              link
              description
              categories {
                name
                id
              }
              childrenFile {
                  childImageSharp {
                      fixed(height: 80, width: 80) {
                          src
                      }
                  }
              }
            }
            name
            childrenFile {
              childImageSharp {
                fixed {
                  src
                }
              }
            }
          }
        }
        categories: allCategory {
            nodes {
                name
                childrenFile {
                    childImageSharp {
                        fixed(height: 80, width: 80) {
                            src
                        }
                    }
                }
            }
        }
    }
`;