import React from "react";
import styled, { css } from "styled-components";

import ReactGA from "react-ga";

const analyticsExternalLinkClicked = (link) => {
    ReactGA.event({ 
        category: 'Resource',
        action: `clicked: ${ link }`
    })
}


const ResourceBox = ({ description, categories, image, name, link }) => {
    return <Container href={ link } target="_blank" onClick={ () => analyticsExternalLinkClicked(link) }>
        <Wrapper>
            <Logo imgUrl={ image } />
            <div style={{ marginLeft: 20 }}>
                <Title>{ name }</Title>
                <Categories>
                    {categories && Object.keys(categories).map((category, index) => {
                        const { name } = categories[category];
                        return <Category key={index}>{ name }</Category>
                    })}
                </Categories>
            </div>
        </Wrapper>
        <Description>{ description }</Description>
    </Container>
}

const Container = styled.a`
    box-shadow: ${ `#a6a6a638` } 0 0 30px;
    background: #f2f2f2;
    text-align: left;
    padding: 20px;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    align-items: start;
    min-width: 320px;
    flex-direction: column;
    transition: all 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0 35px ${ `#a6a6a685` };
    } 
`;

const Wrapper = styled.div`
    display: flex;
`;

const Logo = styled.div`
    width: 80px;
    min-width: 80px;
    height: 80px;
    background: ${ props => props.imgUrl ? css`url(${ props.imgUrl })` : '#a6a6a6' };
    background-size: cover;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #a6a6a668;

    img {
        width: 100%;
    }
`;

const Title = styled.p`
    font-size: 21px;
    font-weight: bold;
    color: #2a1a47;
    text-transform: capitalize;
    margin: 0;
`;

const Categories = styled.div``;

const Category = styled.p`
    font-size: 12px;
    font-weight: bold;
    display: inline-block;
    border-radius: 5px;
    color: #2a1a47;
    margin: 0;
    margin-right: 10px;
`;

const Description = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #9a98a0;
    margin-top: 16px;
    max-width: 35em;
    line-height: 1.5;
`;

export default ResourceBox;